import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Phases from "../../../static/images/seguridad/auditoria.svg"

const Auditoria = () => {
  const data = useStaticQuery(graphql`
    query imagesAuditoria {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Auditorías de Seguridad Informática | Qualoom</title>
        <meta name="title" content="Especialistas en Auditorías de Seguridad Informática | Qualoom"/>
        <meta name="description" content="¿Necesitas una Auditoría de Seguridad Informática? En Qualoom te ayudamos a mejorar y preservar la Protección de tus Datos. ¡Consúltanos YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/auditoria/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/auditoria/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/auditoria/"/>
        <meta property="og:title" content="Especialistas en Auditorías de Seguridad Informática | Qualoom"/>
        <meta property="og:description" content="¿Necesitas una Auditoría de Seguridad Informática? En Qualoom te ayudamos a mejorar y preservar la Protección de tus Datos. ¡Consúltanos YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/auditoria/"/>
        <meta property="twitter:title" content="Especialistas en Auditorías de Seguridad Informática | Qualoom"/>
        <meta property="twitter:description" content="¿Necesitas una Auditoría de Seguridad Informática? En Qualoom te ayudamos a mejorar y preservar la Protección de tus Datos. ¡Consúltanos YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/auditoria/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}},
              {"@type":"ListItem", "position":"3", "name":"¿Qué es una auditoría de seguridad informática?", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/auditoria/", "name": "¿Qué es una auditoría de seguridad informática?"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Auditoría de Seguridad Informática</h1>
              <p>El conjunto de procedimientos para evaluar el nivel de seguridad de tu empresa, consiste en analizar todos los procesos para controlar y verificar que sus políticas de seguridad se cumplen correctamente por todos los miembros de la organización y verificando que ningún ente externo puede alterar y vulnerar los accesos ni la información existente.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Cómo hacer una auditoría de seguridad informática</h2><hr></hr>
          <p>Una auditoría tiene distintas fases necesarias para evaluar la seguridad de tu empresa:</p><img src={Phases} alt="Fases d la auditoría" className="phases-security"></img><br/>
          <h3>¿Cuáles son los beneficios de hacer una auditoría de seguridad?</h3>
          <ul>
            <li>Optimización de los sistemas informáticos</li>
            <li>Identificación temprana de vulnerabilidades y aplicación de parches</li>
            <li>Eliminación de vulnerabilidades y reducción de los riesgos</li>
            <li>Permite tener medidas adecuadas para evitar cualquier incidente antes de que vulnere la seguridad</li>
            <li>Planes de recuperación ante desastres</li>
            <li>Actualización y optimización de las políticas y procedimientos de seguridad</li>
            <li>Evita multas o sanciones por incumplimientos de las leyes y normativas de protección de datos española y europea</li>
            <li>Otras ventajas: reducción de costes (mejor uso de los recursos), mejorar el flujo de trabajo, permitir el teletrabajo seguro, proyección de una mejor imagen empresarial o mejorar las relaciones y seguridad interna</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Realiza una auditoría con Qualoom</h2><hr></hr>
          <p>Para realizar la auditoría de seguridad en tu empresa seguiremos los siguientes pasos:</p>
          <ul>
            <li><b>Planificación inicial:</b> Realizaremos un estudio de la situación actual de tus sistemas informáticos y protocolos de seguridad</li>
            <li><b>Análisis profundo y preciso de los posibles riesgos y amenazas</b> a los que se expone tu empresa</li>
            <li><b>Definiremos las soluciones necesarias que se deben aplicar</b> para eliminar o mitigar las consecuencias de los resultados del análisis</li>
            <li><b>Implementación de los cambios</b>, según su prioridad y siguiendo el calendario previamente definido</li>
            <li><b>Monitorización y evaluación de los resultados:</b> Seguiremos todo el proceso para garantizar los resultados y, si fuera necesario, aplicar modificaciones o ajustes</li>
          </ul><br/>
          <h3>¿Por qué tu empresa necesita una auditoría de seguridad?</h3>
          <p>Este tipo de auditorías te garantizan un análisis en profundidad de todos los aspectos de tu infraestructura que puedan provocar vulnerabilidades y fallos de seguridad que afecten de forma negativa a tu empresa. Nuestro equipo de auditoría te ayudará a entender el mejor modelo de auditoría que se corresponde con tu  organización, a su planificación y posterior ejecución.</p>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default Auditoria
